.mb1 {
  margin-bottom: 0.5rem;
}
.mb2 {
  margin-bottom: 1rem;
}
.mb3 {
  margin-bottom: 1.5rem;
}
.mb4 {
  margin-bottom: 2rem;
}
.mb5 {
  margin-bottom: 2.5rem;
}

.mt1 {
  margin-top: 0.5rem;
}
.mt2 {
  margin-top: 1rem;
}
.mt3 {
  margin-top: 1.5rem;
}
.mt4 {
  margin-top: 2rem;
}
.mt5 {
  margin-top: 2.5rem;
}

.ml1 {
  margin-left: 0.5rem;
}
.ml2 {
  margin-left: 1rem;
}
.ml3 {
  margin-left: 1.5rem;
}
.ml4 {
  margin-left: 2rem;
}
.ml5 {
  margin-left: 2.5rem;
}

.mr1 {
  margin-right: 0.5rem;
}
.mr2 {
  margin-right: 1rem;
}
.mr3 {
  margin-right: 1.5rem;
}
.mr4 {
  margin-right: 2rem;
}
.mr5 {
  margin-right: 2.5rem;
}

/* https://alistapart.com/article/axiomatic-css-and-lobotomized-owls */
.gap-hor-1 > * + * {
  margin-left: 0.5rem;
}
.gap-hor-2 > * + * {
  margin-left: 1rem;
}
.gap-hor-3 > * + * {
  margin-left: 1.5rem;
}
.gap-hor-4 > * + * {
  margin-left: 2rem;
}
.gap-hor-5 > * + * {
  margin-left: 2.5rem;
}

.gap-vert-1 > * + * {
  margin-top: 0.5rem;
}
.gap-vert-2 > * + * {
  margin-top: 1rem;
}
.gap-vert-3 > * + * {
  margin-top: 1.5rem;
}
.gap-vert-4 > * + * {
  margin-top: 2rem;
}
.gap-vert-5 > * + * {
  margin-top: 2.5rem;
}
