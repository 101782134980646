.List {
  padding: 0;
}

.ListItem {
  min-width: 100%;
  line-height: 32px;
  padding: 2px 2px 2px 4px;
}
.ListRow {
  display: flex;
}
.ListItem:nth-child(odd) {
  background: #fafafa;
}

.ListItem__field:not(.ListItem__date) {
  padding-left: 1rem;
}

.ListItem__event {
  flex-grow: 1;
}

.ListItem__date {
  min-width: 114px;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}


.flex-trunc-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.flex-trunc-parent {
  flex-grow: 1;
  
  min-width: 0;
}