:root {
  --border-radius: 5px;
  --primary-color: #157fe2;
  --danger-color: #ad1c1c;
  --font-size: 16px;
  --font-size-body-2: calc(var(--font-size) * 0.8);
  --standard-row: calc(var(--font-size) * 2);
  --form-el-height: calc(var(--font-size) * 2);
  --highlight-color: orange;
  --hover-bg-color: #eaeaea;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
*, *::before, *::after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}
body .react-datepicker-popper {
  z-index: 2;
}

button {
  all: unset;
  /* color: black; */
  /* -webkit-text-fill-color: black; */
  cursor: pointer;
  border: 1px solid grey;
  border-radius: 2px;
  text-align: center;
  font-size: 1rem;
}

button:focus {
  outline: var(--highlight-color) 5px auto;
}

.App__container {
  max-width: 500px;
  margin: 0 auto;
  padding: 1rem 0.5rem 0;
}

.spaceEvenly {
  display: flex;
  justify-content: space-between;
}

.recordForm input {
  font-size: 16px;
}

.label {
  font-size: var(--font-size-body-2);
  /* height: var(--standard-row); */
  display: inline-block;
  margin-top: var(--font-size);
  line-height: var(--font-size);
}

.input {
  font-size: var(--font-size);
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 100%;
  height: 2rem;
  padding: 0 0.5rem;
}

.clear-button {
  all: unset;
  cursor: pointer; 
}
.clear-button:focus {
  outline: 2px solid -webkit-focus-ring-color;
  outline-style: auto;
}

/* Form elements */
.form input, .form button, .button, .input {
  font-size: var(--font-size);
  border: 1px solid #a8a8a8;
  border-radius: var(--border-radius);
  height: var(--form-el-height);
}

.textarea {
  font-size: var(--font-size);
  border: 1px solid #a8a8a8;
  border-radius: var(--border-radius);
  display: block;
  width: 100%;
}

.form input:disabled, .form button:disabled, .button:disabled, .input:disabled {
  color: grey;
  background: lightgray;
  cursor: not-allowed;
}

/* needs better solution than enumeration */
.button:not(.button-primary):not(.button-danger):hover {
  background-color: var(--hover-bg-color);
}

.button-primary {
  color: white;
  background-color: var(--primary-color);
  border-color:  var(--primary-color);
}
.button-danger {
  color: white;
  background-color: var(--danger-color);
  border-color:  var(--danger-color);
}

.button, .button-clear {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.button-clear {
  all: unset;
  cursor: pointer;
}
.button-clear:focus {
  outline: 2px solid var(--primary-color);
  outline-style: auto;
}